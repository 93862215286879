export const firebaseConfig = {
  apiKey: "AIzaSyAI4AvBmfNFFwECUr24cusw8_ACaxzZLoM",
  authDomain: "olapp-6c8ab.firebaseapp.com",
  databaseURL: "https://olapp-6c8ab.firebaseio.com",
  projectId: "olapp-6c8ab",
  storageBucket: "olapp-6c8ab.appspot.com",
  messagingSenderId: "224882441424",
  appId: "1:224882441424:web:9b8b2c7deafb7fde95f2d5",
  measurementId: "G-N79BBXM5NS"
}
